import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { Modal } from 'components';
import EditorLayout from 'components/EditorLayout';
import Sidebar from './components/Sidebar';
import notify from 'notify';
import PDFEditorContext, { PDFEditorContextWrapper } from './context';
import PDFViewer from './pdf/PDFViewer';
import SaveAs from './components/SaveAs';
import CheckList from './components/CheckList';
import AddNoteToEmail from './components/AddNoteToEmail';
import SaveTemplatedLayer from './components/SaveTemplatedLayer';
import ShareDocumentModal from './components/ShareDocumentModal';
import ModalAddSigners from './components/ModalAddSigners';
import ShareSignatureModal from './components/ShareSignatureModal';
import Header from './components/Header';
import { addPeopleForSigning } from './api';

const PDFEditor = () => {
  const { documentIsEditable, setOpenEmailNote, openEmailNote, setPopupFinalChecks, openPopupFinalChecks,setTransactionMembers,editor,editorDispatch } = useContext(PDFEditorContext);
  const [openSaveAs, setOpenSaveAs] = useState(false);
  const [openTemplatedLayer, setOpenTemplatedLayer] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [openShareSignatures, setOpenShareSignatures] = useState(false);
  const [openAddSigners, setOpenAddSigners] = useState(false);
  const [addedSigners, setAddedSigners] = useState([]);

  const content = (
    <>
      {!documentIsEditable && (
        <div className="document-not-editable">
          This document cannot be edited anymore. Please make your alterations and save as a new
          document.
        </div>
      )}
      <StyledContent>
        <PDFViewer />
        <ShareDocumentModal open={openShare} onClose={() => setOpenShare(false)} />
        <ShareSignatureModal
          open={openShareSignatures}
          onClose={() => setOpenShareSignatures(false)}
        />
        <Modal
          open={openSaveAs}
          modalTitle="Save As"
          onClose={() => setOpenSaveAs(false)}
          className="save-as">
          <SaveAs onSuccess={() => setOpenSaveAs(false)} />
        </Modal>
        <Modal
          open={openPopupFinalChecks}
          modalTitle="Offer Checklist"
          onClose={() => notify('Please double check everything and click the button')}
          className="add-note-email">
          <CheckList onSuccess={() => setPopupFinalChecks(false)} />
        </Modal>
        <Modal
          open={openEmailNote}
          modalTitle="Add note to Email"
          onClose={() => setOpenEmailNote(false)}
          className="add-note-email">
          <AddNoteToEmail onSuccess={() => setOpenEmailNote(false)} />
        </Modal>
        <Modal
          open={openTemplatedLayer}
          modalTitle="Save template for next time"
          onClose={() => setOpenTemplatedLayer(false)}
          className="add-note-email">
          <SaveTemplatedLayer onSuccess={() => setOpenTemplatedLayer(false)} />
        </Modal>
        <ModalAddSigners
            modalTitle="Add signer"
            open={Boolean(openAddSigners)}
            onSubmit={ async (formValues) => {
              
              const data = await addPeopleForSigning(formValues);
              data["role"] = data.name; 
              setOpenAddSigners(false);
              addedSigners.push(data);
              setAddedSigners(addedSigners);
              setTransactionMembers(addedSigners);
              console.log(addedSigners);
              editorDispatch({ type: 'SET_NUMBER_OF_NEW_SIGNERS', value: (++editor.numberOfNewSigners) });
            }}
            onClose={() => {setOpenAddSigners(false);}}
          >
          </ModalAddSigners>
      </StyledContent>
    </>
  );

  return (
    <StyledEditorLayout
      content={content}
      sidebar={
        <Sidebar
          openSaveAs={() => setOpenSaveAs(true)}
          openShare={() => setOpenShare(true)}
          openShareSignatures={() => setOpenShareSignatures(true)}
          openAddSigners={() => setOpenAddSigners(true)}
          openSaveTemplate={() => setOpenTemplatedLayer(true)}
        />
      }
      header={<Header />}
    />
  );
};

export default () => (
  <PDFEditorContextWrapper>
    <PDFEditor />
  </PDFEditorContextWrapper>
);

const StyledEditorLayout = styled(EditorLayout)`
  header {
    justify-content: center;

    .logo-container {
      width: 150px;
    }

    .document-navigation {
      margin: auto;
      white-space: nowrap;

      .button {
        margin: 0 10px;
        padding: 3px;

        &:nth-of-type(2) .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .content {
    height: 100%;
    padding: 0;
  }

  .document-not-editable {
    position: sticky;
    top: 0;
    z-index: 1000;
    background: ${props => props.theme.colors.salmonPink};
    text-align: center;
    color: white;
    padding: 5px 10px;
  }
`;

const StyledContent = styled.div`
  overflow: auto;
  padding: 30px;
  display: flex;

  .share-and-sign {
    .modal-content {
      min-width: 600px;
    }
  }
`;
