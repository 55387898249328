import React, { useContext } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import Modal, { ModalProps } from 'components/Modal';
import { useForm, Controller } from 'react-hook-form';
import Button from 'components/Button';
import { Input } from 'components';

interface ModalAddPeopleSign extends ModalProps {
    onSubmit: () => Promise<[]>;
}



const ModalAddPeopleSign: React.FC<ModalAddPeopleSign> = ({
  onSubmit,
  onClose,
  open,
  className,
  children,
  ...props
}) => {

    const {
    register,
    watch,
    setValue,
    formState: { errors }
    } = useForm({
    });


    const { control, handleSubmit } = useForm();

  return (
    <StyledModalPeopleSignMembers
      className={clsx('confirm', className)}
      onClose={onClose}
      open={open}
      {...props}>
      {children}
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Controller
            control={control}
            name="signer_name"
            rules={{ required: 'Required' }}
            render={({ field, formState: { errors } }) => (
                <Input
                    label="Name*"
                    className="area_for_input"
                    placeholder=" Full Name"
                    error={errors.signer_name?.message}
                    data-cy="input_description"
                    {...field}
                />
            )}
        />
        <Controller
              control={control}
              name="signer_email"
              rules={{ required: 'Required' }}
              render={({ field, formState: { errors } }) => (
                  <Input
                      label="Email*"
                      className="area_for_input"
                      placeholder="Email address"
                      error={errors.signer_email?.message}
                      data-cy="input_description"
                      {...field}
                  />
              )}
          />
        <Button type="submit" data-cy="button_submit">
            Add
        </Button>
      </StyledForm>
    </StyledModalPeopleSignMembers>
  );
};

export default ModalAddPeopleSign;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 16px;
  }

  & > .button {
    height: 40px;
  }

  .optional {
    color: ${props => props.theme.colors.grayDark};
  }
`;


const StyledModalPeopleSignMembers = styled(Modal)`
  .peopleXp {
    padding: 0;width:100%;
  }
  .peopleXp tr:nth-child(odd){
    background-color:#dedede4a;
  }
  .peopleXp td{padding: 10px 0px;}
  .peopleXp span{cursor: pointer;
    color: #df1721;
    font-weight: bold;
  }
  .modal-content {
    min-width: 300px;
    font-size: 16px;
    line-height: 24px;
  }

  .modal-title {
    padding: 12px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .modal-body {
    p {
      font-size: 14px;
    }
  }

  button.large, .dropdown-wide {
    width: 100%;
    margin-top: 16px;
  }

  .dropdown-wide > label, .attorney__input > label {
    font-size: 12px;
  }

  .sale-side__checkbox {
    margin-bottom: 16px;
  }
`;
