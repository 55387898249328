import { EditableNode } from './types.d';

export type EditorReducerAction =
  | {
      type: 'SET_IS_ADDING_NODE';
      value: { isAddingNode: boolean; newNode: Partial<EditableNode> | undefined };
    }
  | { type: 'SET_IS_DELETING_NODE'; value: boolean }
  | { type: 'SET_NODES'; value: EditableNode[] }
  | { type: 'ADD_NODE'; value: EditableNode }
  | { type: 'EDIT_NODE'; value: EditableNode }
  | { type: 'DELETE_NODE'; value: number }
  | { type: 'SET_DOCUMENT_URL'; value: string }
  | { type: 'SET_DOCUMENT_NAME'; value: string }
  | { type: 'SET_TOTAL_PAGES'; value: number }
  | { type: 'SET_DOCUMENT_LOADED'; value: boolean }
  | { type: 'SET_BUTTON_TEXT'; value: string }
  | { type: 'SET_NEXT_DOCUMENT_ID'; value: number }
  | { type: 'SET_NUMBER_OF_NEW_SIGNERS'; value: number }
  | { type: 'SET_DOCUMENT_TYPE'; value: string }
  | { type: 'SET_CHANGES_SAVED'; value: boolean }
  | { type: 'NUMBER_OF_ADDED_SIGNATURE_OR_INITIALS_FIELDS'; value: number };

export type EditorReducerState = {
  documentUrl?: string;
  documentType?: string;
  documentName?: string;
  isAddingNode: boolean;
  isDeletingNode: boolean;
  newNode?: Partial<EditableNode>;
  editableNodes: EditableNode[];
  totalPages?: number;
  documentLoaded: boolean;
  changesSaved: boolean;
  nextDocument: number;
  buttonText: string;
  numberOfAddedSignatureOrInitialsFields: number;
  numberOfNewSigners: number;
};

export const editorReducer = (
  state: EditorReducerState,
  action: EditorReducerAction
): EditorReducerState => {
  switch (action.type) {
    case 'SET_IS_ADDING_NODE':
      return { ...state, ...action.value };
    case 'SET_IS_DELETING_NODE':
      return { ...state, isDeletingNode: action.value };
    case 'SET_NODES':
      return { ...state, editableNodes: action.value };
    case 'ADD_NODE':
      return {
        ...state,
        changesSaved: false,
        editableNodes: [...state.editableNodes, { ...action.value, isNew: true }]
      };
    case 'EDIT_NODE':
      return {
        ...state,
        changesSaved: false,
        editableNodes: state.editableNodes.map(item =>
          item.id === action.value.id ? action.value : item
        )
      };
    case 'DELETE_NODE':
      return {
        ...state,
        changesSaved: false,
        editableNodes: state.editableNodes.filter(item => item.id !== action.value)
      };
    case 'SET_DOCUMENT_URL':
      return { ...state, documentUrl: action.value };
    case 'SET_DOCUMENT_TYPE':
      return { ...state, documentType: action.value };
    case 'SET_DOCUMENT_NAME':
      return { ...state, documentName: action.value };
    case 'SET_BUTTON_TEXT':
      return { ...state, buttonText: action.value };
    case 'SET_NEXT_DOCUMENT_ID':
      return { ...state, nextDocument: action.value };
    case 'SET_TOTAL_PAGES':
      return { ...state, totalPages: action.value };
    case 'SET_DOCUMENT_LOADED':
      return { ...state, documentLoaded: action.value };
    case 'SET_CHANGES_SAVED':
      return { ...state, changesSaved: action.value };
    case 'SET_NUMBER_OF_NEW_SIGNERS':
      return { ...state, numberOfNewSigners: action.value };
    case 'NUMBER_OF_ADDED_SIGNATURE_OR_INITIALS_FIELDS':
      return { ...state, numberOfAddedSignatureOrInitialsFields: action.value };
    default:
      return state;
  }
};

export default editorReducer;
