import React, { useContext, useRef, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import clsx from 'clsx';
import styled from '@emotion/styled';
import notify from 'notify';
import PDFEditorContext from '../context';
import { EditableNode } from '../types.d';
import EditableNodeComponent from './EditableNodeComponent';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PDFViewerProps {
  test?: any;
}

const PDFViewer: React.FC<PDFViewerProps> = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { editor, editorDispatch, currentPage, currentPageSize, setCurrentPageSize } = useContext(
    PDFEditorContext
  );
  const [strikethroughPositionStart, setStrikethroughPositionStart] = useState<{
    x: number;
    y: number;
  }>();

  const handleDocumentWrapperClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!editor.isAddingNode) return;
    if (editor.newNode?.type === 'strikethrough') return;
    if (!editor.newNode?.type) {
      notify('New node is missing type');
      return;
    }
    if (!currentPageSize) {
      notify('Page size is undefined');
      return;
    }
    e.stopPropagation();
    const wrapper = wrapperRef.current;
    const rect = wrapper!.getBoundingClientRect();
    const x = e.clientX - rect?.left;
    const y = e.clientY - rect?.top;
    const position = { x, y };
    const pagePosition = {
      x: position.x / currentPageSize.width,
      y: position.y / currentPageSize.height
    };

    editorDispatch({
      type: 'ADD_NODE',
      value: {
        id: Date.now(),
        position,
        pagePosition,
        page: currentPage,
        text: '',
        customNode: true,
        ...editor.newNode
      } as EditableNode
    });
    editorDispatch({
      type: 'SET_IS_ADDING_NODE',
      value: { isAddingNode: false, newNode: undefined }
    });
  };

  const handleDocumentWrapperMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!editor.isAddingNode && editor.newNode?.type !== 'strikethrough') return;
    e.stopPropagation();
    const wrapper = wrapperRef.current;
    const rect = wrapper!.getBoundingClientRect();
    const x = e.pageX - rect.left;
    const y = e.pageY - rect.top - 8;
    const position = { x, y };
    setStrikethroughPositionStart(position);
  };

  const handleDocumentWrapperMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (
      !editor.isAddingNode ||
      editor.newNode?.type !== 'strikethrough' ||
      !strikethroughPositionStart ||
      e.button !== 0
    ) {
      return;
    }

    if (!currentPageSize) {
      notify('Page size is undefined');
      return;
    }

    const wrapper = wrapperRef.current;
    const rect = wrapper!.getBoundingClientRect();
    const x = e.clientX - rect?.left;
    const y = e.clientY - rect?.top;
    const strikethroughPositionEnd = { x, y };

    const strikethrougWidth = strikethroughPositionEnd.x - strikethroughPositionStart.x;

    const pagePosition = {
      x: strikethroughPositionStart.x / currentPageSize.width,
      y: strikethroughPositionStart.y / currentPageSize.height
    };

    const strikethrougWidthRelative = strikethrougWidth/currentPageSize.width;

    editorDispatch({
      type: 'ADD_NODE',
      value: {
        id: Date.now(),
        position: strikethroughPositionStart,
        pagePosition,
        page: currentPage,
        text: '',
        strikethrougWidth,
        strikethrougWidthRelative,
        ...editor.newNode
      } as EditableNode
    });
    setStrikethroughPositionStart(undefined);
  };

  const getNodeComponent = node => {
    return (
      <EditableNodeComponent
        key={node.id}
        data={node}
        isDeleting={editor.isDeletingNode}
        onChange={node => editorDispatch({ type: 'EDIT_NODE', value: node })}
        onDelete={node => {
          editorDispatch({ type: 'DELETE_NODE', value: node.id });
          editorDispatch({ type: 'SET_IS_DELETING_NODE', value: false });
        }}
      />
    );
  };

  return (
    <StyledPDFViewer
      ref={wrapperRef}
      className={clsx('pdf-viewer', { 'is-adding-node': editor.isAddingNode })}
      onClick={handleDocumentWrapperClick}
      onMouseUp={handleDocumentWrapperMouseUp}
      onMouseDown={handleDocumentWrapperMouseDown}>
      {editor.documentLoaded &&
        editor.editableNodes.filter(node => node.page === currentPage).map(getNodeComponent)}
      <Document
        file={editor.documentUrl}
        onLoadSuccess={pdf => {
          editorDispatch({ type: 'SET_DOCUMENT_LOADED', value: true });
          editorDispatch({ type: 'SET_TOTAL_PAGES', value: pdf.numPages });
        }}>
        <Page
          pageNumber={currentPage}
          width={820}
          onLoadSuccess={() => {
            setCurrentPageSize({
              width: wrapperRef.current!.clientWidth,
              height: wrapperRef.current!.clientHeight
            });
          }}
        />
      </Document>
    </StyledPDFViewer>
  );
};

export default PDFViewer;

const StyledPDFViewer = styled.div`
  position: relative;
  display: inline-block;
  margin: auto;
  border: 1px solid #00000050;

  &.is-adding-node {
    cursor: crosshair;
  }

  .react-pdf__Page {
    overflow: hidden;
  }

  .react-pdf__Page__canvas {
    margin: auto;
  }
`;
