import React, { useContext,useState } from 'react';
import styled from '@emotion/styled';
import notify from 'notify';

import { Button, Checkbox } from 'components';

const CheckList = ({ onSuccess }) => {

  const [checkedParking, setCheckedParking] = useState(Boolean(false));
  const [checkedStorage, setCheckedStorage] = useState(Boolean(false));
  const [checkedTax, setCheckedTax] = useState(Boolean(false));
  const [checkedEM, setCheckedEM] = useState(Boolean(false));
  const [checkedCCC, setCheckedCCC] = useState(Boolean(false));
  const [checkedNames, setCheckedNames] = useState(Boolean(false));
  const [checkedBBData, setCheckedBBData] = useState(Boolean(false));

  function seeIfAllChecked(){
    if(checkedParking && checkedStorage && checkedTax && checkedEM && checkedCCC && checkedNames && checkedBBData){
        onSuccess();
    }else{
        notify('Please double check everything and click the button');
    }
  }

  return (
    <StyledCL className="save-form">
      <Checkbox
          checked={checkedParking}
          onChange={e =>  {setCheckedParking(e.target.checked)}}
          label="Did you check PARKING on the MLS/Data Sheet"
          disabled={false}
        />
      
        <Checkbox
          checked={checkedStorage}
          onChange={e =>  {setCheckedStorage(e.target.checked)}}
          label="Did you check STORAGE on the MLS/Data Sheet"
          disabled={false}
        />

        <Checkbox
          checked={checkedTax}
          onChange={e =>  {setCheckedTax(e.target.checked)}}
          label="Did you check TAX PRORATION on the MLS/Data Sheet"
          disabled={false}
        />

        <Checkbox
          checked={checkedEM}
          onChange={e =>  {setCheckedEM(e.target.checked)}}
          label="Did you check if EM total is % or $"
          disabled={false}
        />

        <Checkbox
          checked={checkedCCC}
          onChange={e =>  {setCheckedCCC(e.target.checked)}}
          label="Did you check for Closing Cost Credit"
          disabled={false}
        />

        <Checkbox
          checked={checkedNames}
          onChange={e =>  {setCheckedNames(e.target.checked)}}
          label="Did you double check the buyer names"
          disabled={false}
        />

        <Checkbox
          checked={checkedBBData}
          onChange={e =>  {setCheckedBBData(e.target.checked)}}
          label="Do not forget to double check the buyer broker's data on page 13"
          disabled={false}
        />

      <Button onClick={() => seeIfAllChecked()} className="save-form__btn">
        I double checked everything
      </Button>
    </StyledCL>
  );
};

export default React.memo(CheckList);

const StyledCL = styled.div`
.checkbox {
    display: flex;
    width: 100%;
    max-width: 300px;
    margin: 0 0 16px;
  }
  .save-form {
    display: flex;
    flex-direction: column;

    
    &__btn {
      width: 100%;
      max-width: 300px;
      display: block;
      margin-top:40px
    }
  }
`;
