import React, { useContext, useEffect ,useState } from 'react';

import styled from '@emotion/styled';
import Accordion from 'components/Accordion';
import notify from 'notify';
import { Button, Dropdown } from 'components';
import clsx from 'clsx';
import { AuthContext } from 'auth';
import PDFEditorContext from '../context';

interface SidebarProps {
  openShare: () => void;
  openShareSignatures: () => void;
  openSaveAs: () => void;
  openAddSigners: () => void;
  openSaveTemplate: () => void;
}



var peopleRoles = {};

const Sidebar: React.FC<SidebarProps> = ({ openShare, openShareSignatures, openSaveAs, openAddSigners , openSaveTemplate}) => {
  const { profile } = useContext(AuthContext);
  const [signatureOptions, setSignatureOptions] = useState<any>(false);
  const {
    editor,
    editorDispatch,
    saveDocument,
    documentIsEditable,
    transactionMembers,
  } = useContext(PDFEditorContext);
  //console.log(transactionMembers);
  useEffect(() => {
      var result = {};
      console.log(peopleRoles);
      result = transactionMembers?.filter(role => Boolean(role.role))
        .map(role => ({ value: role.role, text: role.role, people_id: role.id }));
      if(editor.documentType == ""){
        var nextKey = Object.keys(result).length;
        result[nextKey] = { value: "adding", text: "Add Signer" };
      }
      for (const key in result) {
          peopleRoles[result[key].value] = result[key].people_id;
      }
      setSignatureOptions(result);
    
  }, [editor.documentType,editor.numberOfNewSigners,transactionMembers]);


  const handleShareClick = () => {
    notify('Please use the Share button in the workspace for this document.');
    return;
    if (!editor.changesSaved) {
      notify('Changes have been made. Please save first');
      return;
    }
    openShare();
  };

  const handleRequestSignaturesClick = () => {
    if (!editor.changesSaved) {
      notify('Changes have been made. Please save first');
      return;
    }
    openShareSignatures();
  };

  return (
    <StyledSidebar className="editor-sidebar">
      <Accordion defaultOpen label="Edit">
        <Button
          className="editor-sidebar__item"
          simple
          onClick={() => {
            editorDispatch({
              type: 'SET_IS_ADDING_NODE',
              value: { isAddingNode: true, newNode: { type: 'field' } }
            });
            notify(
              'Click anywhere on the document, and add an Input Box which can be autofilled with Project Data'
            );
          }}>
          Add Field
        </Button>
        <Button
          className="editor-sidebar__item"
          simple
          onClick={() => {
            editorDispatch({
              type: 'SET_IS_ADDING_NODE',
              value: { isAddingNode: true, newNode: { type: 'text' } }
            });
            notify('Click anywhere on the document, and add one sentence of text');
          }}>
          Add Text
        </Button>
        <Button
          className="editor-sidebar__item"
          simple
          onClick={() => {
            notify('Click on any of the added elements to delete it');
            editorDispatch({
              type: 'SET_IS_DELETING_NODE',
              value: true
            });
          }}>
          Delete Field
        </Button>
        <Button
          className={clsx('editor-sidebar__item', {
            active: editor.newNode?.type === 'strikethrough'
          })}
          simple
          onClick={() => {
            if (!editor.isAddingNode) {
              editorDispatch({
                type: 'SET_IS_ADDING_NODE',
                value: { isAddingNode: true, newNode: { type: 'strikethrough' } }
              });
              notify(
                'Click anywhere on the document, hold your mouse and drag to create a strikethrough line'
              );
            } else {
              editorDispatch({
                type: 'SET_IS_ADDING_NODE',
                value: { isAddingNode: false, newNode: undefined }
              });
            }
          }}>
          Strikethrough
        </Button>
      </Accordion>
      <Accordion defaultOpen label="E-Signature">
      {signatureOptions &&
        <StyledDropdown
          options={signatureOptions}
          value={null}
          onChange={value => {
            if(value == 'adding'){
              openAddSigners();
            }else{
              notify('Click anywhere on the document to add signature field');
              editorDispatch({
                type: 'SET_IS_ADDING_NODE',
                value: { isAddingNode: true, newNode: { type: 'signature', role: value, peopleId: peopleRoles[value] } }
              });
            }
            
          }}
          label="Add Signature Field"
          buttonLike
          className="editor-sidebar__item"
        />
        }
        {signatureOptions &&
        <StyledDropdown
          options={signatureOptions}
          value={null}
          onChange={value => {
            if(value == 'adding'){
              openAddSigners();
            }else{
              notify('Click anywhere on the document to add initials');
              editorDispatch({
                type: 'SET_IS_ADDING_NODE',
                value: { isAddingNode: true, newNode: { type: 'initials', role: value, peopleId: peopleRoles[value] } }
              });
            }
            
          }}
          label="Add Initials Field"
          buttonLike
          className="editor-sidebar__item"
        />
        }
        <Button
          className="editor-sidebar__item"
          simple
          onClick={() => {
            editorDispatch({
              type: 'SET_IS_ADDING_NODE',
              value: {
                isAddingNode: true,
                newNode: { type: 'user_signature', user: profile?.name }
              }
            });
            notify('Click anywhere on the document to add your signature');
          }}>
          Sign
        </Button>
      </Accordion>
      {editor.documentType != "" &&
      <Accordion defaultOpen label="Store">
        <Button className="editor-sidebar__item" simple onClick={saveDocument}>
          Save
        </Button>
        <Button className="editor-sidebar__item" simple onClick={openSaveAs}>
          Save As
        </Button>
        <Button className="editor-sidebar__item" simple onClick={openSaveTemplate}>
          Save Working Template
        </Button>
      </Accordion>
      }
      {editor.documentType != "" &&
      <Accordion defaultOpen label="Send">
        <Button className="editor-sidebar__item" simple onClick={handleShareClick}>
          Share
        </Button>
        <Button
          className="editor-sidebar__item"
          simple
          disabled={documentIsEditable}
          onClick={handleRequestSignaturesClick}>
          Request Signatures
        </Button>
      </Accordion>
      }
    </StyledSidebar>
  );
};

export default React.memo(Sidebar);

const StyledSidebar = styled.div`
  z-index: 1001;

  .editor-sidebar {
    &__item {
      width: 100%;
      display: block;
      padding: 5px 0;
      text-align: left;
      &.active {
        color: ${props => props.theme.colors.red};
      }
      &:disabled {
        background: none;
      }
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  .dropdown-button {
    border: none;
    padding: 0;
    height: auto;
    label {
      font-weight: 600;
      color: #000000;
      cursor: pointer;
    }
  }
  &.open {
    .dropdown-button {
      label {
        color: ${props => props.theme.colors.red};
      }
    }
  }
  &.button-like {
    display: block;
  }
  .icon {
    display: none;
  }
  .dropdown-options {
    width: auto;

    .option {
      padding: 8px 12px;
    }
  }
`;
