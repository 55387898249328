import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';

import { Button, Input } from 'components';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { saveTemplatedLayer } from '../api';
import PDFEditorContext from '../context';

const SaveTemplatedLayer = ({ onSuccess }) => {
  const { editor } = useContext(
    PDFEditorContext
  );
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm();

  const submit = async form => {
    try {
        const nodesWithoutNewAttribute = editor.editableNodes.map(({ isNew: _isNew, ...item }) => item);
        
        await saveTemplatedLayer(JSON.stringify(nodesWithoutNewAttribute),form.templatedName);
        notify('Saved for next time');
        onSuccess();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  return (
    <StyledNoteAdd className="save-form" onSubmit={handleSubmit(submit)}>
      <Input
        {...register('templatedName')}
        error={errors.templatedName?.message}
        label="Name for Template"
        placeholder="Enter unique template name"
        className="save-form__input"
      />

      <Button onClick={() => null} disabled={isSubmitting} className="save-form__btn">
        {isSubmitting ? 'Sending...' : 'Send'}
      </Button>
    </StyledNoteAdd>
  );
};

export default React.memo(SaveTemplatedLayer);

const StyledNoteAdd = styled.form`
  .save-form {
    display: flex;
    flex-direction: column;
    &__input {
      display: flex;
      width: 100%;
      max-width: 300px;
      margin: 0 0 16px;
      &.dropdown {
        flex-direction: column;
      }
    }
    &__btn {
      width: 100%;
      max-width: 300px;
      display: block;
    }
  }
`;
